.coin.row {
    margin: 0;
    .CashInput {
        padding: 0 10px 0 0;
        &:first-child {
            padding-left: 0;
        }
    }
}
.titleMoney {
    font-size: 16px;
    margin-bottom: 5px;
}
.CashInput {
    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 10px;
    }
    svg {
        margin-right: 10px;
    }
}