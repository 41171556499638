$blue: #1b55e3;
.CartContainer {
    &-Item {
        &-Qty {
            width: 50px;
        }
    }
    .OrderOption {
        // margin-bottom: 10px;
        .row {
            margin: 0;
        }
        .OrderOption-Item {
            cursor: pointer;
            border: 1px solid transparent;
            border-radius: 5px;
            background-color: transparent;
            color: $blue;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
            flex-wrap: wrap;
            &:hover {
                border: 1px solid #1b55e3;
                background-color: #1b55e3;
                color: #ffffff;
                -webkit-box-shadow: 0px 0px 3px 0px rgba(27, 85, 227, 1);
                -moz-box-shadow: 0px 0px 3px 0px rgba(27, 85, 227, 1);
                box-shadow: 0px 0px 3px 0px rgba(27, 85, 227, 1);
            }
            span {
                font-size: 20px;
                width: 100%;
            }
        }
    }
    .CartTitle {
        background-color: #1b55e3;
        border-radius: 5px 5px 0 0;
        color: #ffffff;
        text-align: left;
        padding: 20px;
        font-size: 18px;
        font-weight: 600;
        position: relative;
        .OrderDate {
            font-size: 12px;
            display: block;
            font-weight: 400;
        }
        .BtnMember {
            background-color: #ffffff;
            border-radius: 50px;
            color: #1b55e3;
            cursor: pointer;
            position: absolute;
            top: 30px;
            right: 20px;
            display: flex;
            align-items: center;
            padding: 10px 10px;
            font-weight: 400;
            font-size: 14px;
            height: 40px;
            &:hover {
                background-color: #f4f5f8;
                -webkit-box-shadow: 0px 0px 10px 0px #c8c9cc;
                -moz-box-shadow: 0px 0px 10px 0px #c8c9cc;
                box-shadow: 0px 0px 10px 0px #c8c9cc;
            }
            &-Button {
                display: flex;
            }
        }
        .ChooseLogo {
            font-size: 18px;
            display: flex;
            margin-right: 7px;
            border-radius: 50%;
            background: #1b55e3;
            color: #ffffff;
            width: 20px;
            height: 20px;
            align-items: center;
            justify-content: center;
        }
    }
    .OrderDetails {
        background-color: #ffffff;
        padding: 5px 0 15px;
        min-height: 250px;
        border-radius: 0 0 5px 5px;
        .empty {
            font-size: 20px;
            text-align: center;

            .CartNoData {
                color: #999999;
                font-weight: bold;
            }
        }
        hr {
            border-top: 2px solid #1b55e3;
            margin: 0 0 1rem;
        }
        .OrderDetail-Item {
            list-style: none;
            padding: 0;
            margin-bottom: 0;
            li {
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding-top: 7px;
                padding-bottom: 5px;
                &:first-child {
                    padding-top: 5px;
                }
                &:last-child {
                    border-bottom: none;
                    padding-bottom: 7px;
                }
            }
        }
        .form-label {
            display: block;
        }
        .MinusQty,
        .plusQty {
            cursor: pointer;
            color: #1b55e3;
            font-size: 18px;
        }
        .Input-Qty {
            border-radius: 5px;
            border: 1px solid #1b55e3;
            margin: 0 5px;
            width: 45px;
            text-align: center;
            &:focus {
                border: 1px solid #1b55e3;
            }
        }
        .CartDiskon {
            align-items: center;
            margin: 10px 0 10px 50px;
            &.active {
                display: flex;
            }
            label {
                margin: 0 10px 0 0;
            }
        }
        .Cart-Item-Title-Price {
            // max-width: 160px;
            padding: 0 10px;
            // width: 100%;
        }
        .CartPrice {
            font-size: 12px;
        }
        .Cart-Item-Subtotals {
            color: #1b55e3;
            font-size: 14px;
            max-width: 100px;
            font-weight: 600;
            width: 100%;
            text-align: left;
            padding: 0;
            &.MultiPrice {
                max-width: 190px;
                select {
                    font-size: 14px;
                    padding: 5px 2px;
                    margin: 5px 0;
                }
            }
        }
        .Cart-Item-Remove {
            margin: 0;
            .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 10px;
                height: 30px;
                width: 30px;
                background-color: transparent;
                padding: 5px;
                border-radius: 50%;
                color: #c82333;
                &:focus {
                    box-shadow: none;
                }
            }
        }
        .Cart-Sub-Total,
        .Cart-Discount,
        .Cart-Grand-Total {
            margin-bottom: 5px;
            padding: 0 10px;
            span {
                float: right;
                font-weight: 600;
            }
        }
        .Cart-Sub-Total {
            margin-top: 10px;
        }
        .Cart-Discount span,
        .Cart-Discount svg {
            color: #c82333;
        }
        .CartName {
            max-width: 200px;
        }
        .Cart-Discount {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 0 0;
            .descDiscount {
                display: flex;
                align-items: center;
                color: #c82333;
            }
            svg {
                cursor: pointer;
                margin-right: 10px;
            }
        }
        .Cart-Grand-Total span {
            font-weight: 600;
            font-size: 18px;
        }
        .Cart-Grand-Button-Payment {
            margin-top: 5px;
            padding: 0;
            .btn {
                font-size: 13px;
                width: 100%;
                padding: 5px 10px;
                .nominal {
                    display: flex;
                    justify-content: center;
                    font-size: 18px;
                    font-weight: 700;
                }
            }
        }
        .NameCols,
        .qtyCols,
        .discPercentDrop {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        .Cart-Item-Qty {
            display: flex;
            align-items: center;
        }
        .qtyCols {
            margin-right: 15px;
        }
        .discPercentDrop {
            background-color: transparent;
            border: 1px solid #1b55e3;
            color: #1b55e3;
            border-radius: 50%;
            padding: 5px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            font-size: 14px;
            margin-left: 15px;
            cursor: pointer;
            &.active,
            &:hover {
                background-color: #1b55e3;
                color: #ffffff;
                border-color: #1b55e3;
            }
        }
        .summaryRow {
            margin: 0;
            padding: 0 10px;
        }
        .Cart-Payment {
            margin-top: 5px;
            padding: 0 5px 0 0;
            &-Btn-Up {
                height: 100%;
            }
        }
        .discountBtnClick {
            padding: 0 10px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #f4f5f8;
            border-radius: 5px;
            cursor: pointer;
            background-color: #f4f5f8;
            color: #000000;
            &.active {
                border: 1px solid #1b55e3;
                color: #ffffff;
                background-color: #1b55e3;
            }
            span {
                font-size: 16px;
                font-weight: 400;
            }
            .discInputs {
                margin: 0;
                background: transparent;
                border: none;
                font-weight: 700;
                text-align: right;
                width: 120px;
                border-bottom: 2px solid #ccc;
                border-radius: 0;
                padding: 0;
                height: 25px;
            }
            &.active .discInputs {
                color: #fff;
                border-bottom: 2px solid #fff;
            }
        }
        .BtnPayment {
            background-color: #f4f5f8;
            border-radius: 5px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            padding: 10px 0;
            height: 100%;
            &:hover {
                background-color: #c8c9cc;
            }
            .payDesc {
                display: flex;
                align-items: center;
                font-size: 18px;
            }
            span {
                font-size: 18px;
                display: flex;
                margin: 0 10px 0 0;
            }
            .ChooseLogo {
                width: 30px;
            }
        }
    }
}
.PaymentModal {
    .PaymentContainer {
        .PaymentItems {
            padding: 10px 5px;
        }
        .PaymentItem {
            border: 1px solid transparent;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-wrap: wrap;
            padding: 15px 0;
            &:hover {
                border-radius: 5px;
                border: 1px solid transparent;
                -webkit-box-shadow: 0px 0px 10px 0px #c8c9cc;
                -moz-box-shadow: 0px 0px 10px 0px #c8c9cc;
                box-shadow: 0px 0px 10px 0px #c8c9cc;
            }
            img {
                width: 50px;
            }
            &.linkaja img,
            &.bri img {
                width: 30px;
            }
            .row {
                width: 100%;
                margin: 0;
            }
            svg {
                font-size: 30px;
                margin: 0 auto;
            }
        }
        img {
            display: block;
            margin: 0 auto;
            width: 100%;
        }
        .LogoName {
            font-weight: 600;
            text-align: left;
            display: flex;
            align-items: center;
        }
        label {
            margin: 0;
            cursor: pointer;
            width: 100%;
        }
        .RadioPayment {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            input {
                position: relative;
                cursor: pointer;
                margin-left: 0;
                margin-top: 0;
            }
        }
        .RadioPaymentLabel {
            padding: 0;
        }
        .Logo {
            display: flex;
            align-items: center;
        }
    }
}
.modal-backdrop.CancelOrderBackdrop {
    z-index: 1050;
    &.show {
        opacity: 0.8;
    }
}
.MembersModal {
    .MemberContainer {
        .MemberItems {
            padding: 10px 5px;
        }
        .MemberItem {
            border: 1px solid transparent;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-wrap: wrap;
            padding: 15px 0;
            &:hover {
                border-radius: 5px;
                border: 1px solid transparent;
                -webkit-box-shadow: 0px 0px 10px 0px #c8c9cc;
                -moz-box-shadow: 0px 0px 10px 0px #c8c9cc;
                box-shadow: 0px 0px 10px 0px #c8c9cc;
            }
            .row {
                width: 100%;
                margin: 0;
            }
        }
        label {
            margin: 0;
            cursor: pointer;
            width: 100%;
        }
        .RadioMember {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            input {
                position: relative;
                cursor: pointer;
                margin-left: 0;
                margin-top: 0;
            }
        }
        .RadioMemberLabel {
            padding: 0;
        }
    }
    &.PlaceOrder {
        .MemberItems,
        .PlaceOrderContainer {
            padding: 0;
        }
        .form-label {
            margin-bottom: 5px;
        }
        .kembalian {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex-flow: column;
        }
        .kembalian span {
            color: #1b55e3;
            float: right;
            font-size: 30px;
            font-weight: 600;
        }
        .payButton {
            margin-top: 15px;
            padding: 0;
            .btn {
                padding: 10px;
            }
            .PlaceOrderBtn {
                width: 48%;
                float: left;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    font-size: 20px;
                    margin-right: 7px;
                }
            }
            .Cancel {
                float: right;
            }
        }
    }
    &.CancelOrder {
        .modal-content {
            height: 100%;
            text-align: center;
        }
        .iconCancel {
            display: flex;
            height: 200px;
            font-size: 100px;
            color: #1b55e3;
            justify-content: center;
            align-items: center;
        }
        .Button {
            display: flex;
            justify-content: space-between;
        }
        .btn {
            width: 48%;
        }
    }
}
