.Transaction {
	&-Body {
		margin-top: 15px;
		padding: 10px;
		&-Categories {
			padding: 0px;
			list-style: none;
			margin-bottom: 0;
			&-Item {
				background-color: #ffffff;
				border-radius: 5px;
				padding: 10px;
				cursor: pointer;
				text-align: center;
				margin-bottom: 15px;
			}
			&-Item:last-child {
				margin-bottom: 0;
			}
			&-Item .img-category {
				height: 30px;
				width: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 auto;
				img {
					width: 100%;
				}
				svg {
					font-size: 35px;
				}
			}
		}
		&-Item {
			&-Active {
				background-color: #1b55e3;
				color: #ffffff;
				// -webkit-box-shadow: 0px 0px 7px 0px rgba(27, 85, 227, 1);
				// -moz-box-shadow: 0px 0px 7px 0px rgba(27, 85, 227, 1);
				// box-shadow: 0px 0px 7px 0px rgba(27, 85, 227, 1);
			}
		}
	}
}

@media (min-width: 768px) {
	.Transaction {
		&-Body {
			margin-top: 15px;
			padding: 10px;
			&-Left {
				&-Categories.col-md-2 {
					flex: 0 0 15%;
					max-width: 15%;
					max-height: 90vh;
					// overflow: scroll;
				}
				&-Products.col-md-10 {
					flex: 0 0 85%;
					max-width: 85%;
				}
			}
			&-Categories-Item {
				font-size: 14px;
			}
		}
	}
}
