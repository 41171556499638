.navbar {
	&.navbar-dark .navbar-nav .nav-link {
		color: #ffffff;
	}
	.navbar-brand {
		position: relative;
		pre {
			font-size: 9px;
			color: #fff;
			position: absolute;
			bottom: 0px;
			right: 0;
			margin: 0;
		}
	}
	.navbar-nav {
		width: 47%;
		flex: 0 0 47%;
	}
	background-color: #1b55e3;
	.dropdown-toggle.nav-link {
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			margin-right: 10px;
			margin-top: -5px;
			font-size: 20px;
		}
	}
	.OrderOption-Item {
		font-size: 14px;
		color: #ffffff;
		cursor: pointer;
		align-items: center;
		display: flex;
		&.History {
			// width: 23%;
			// flex: 0 0 23%;
		}
		span {
			margin-right: 10px;
		}
	}
}
