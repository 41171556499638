.List-Product {
	padding: 0;
}
.List-HeadProduct.row {
	background-color: #ffffff;
	border-radius: 5px;
	margin: 0 0 20px 0;
	padding: 10px 20px;
    align-items: center;
	h3 {
		margin: 0;
	}
	.Title-Category {
		padding-left: 0;
	}
	.Icon-Search {
		font-size: 23px;
		padding: 0;
		text-align: center;
		color: #1b55e3;
	}
	.Search-Form {
		padding-right: 0px;
		display: flex;
    	align-items: center;
		.form-control {
			border: 2px solid #1b55e3;
			border-radius: 50px;
			padding: 0rem 0.75rem;
			height: 50px;
		}
	}
	.Barcode-Scan {
		padding: 0 0 0 12px;
		height: 50px;
		width: 50px;
		.btn {
			border-radius: 50px;
			height: 100%;
		}
		/* .btn {
		    display: flex;
		    justify-content: center;
		    align-items: center;
		    width: 100%;
		    height: 100%;
		    background-color: #ffffff;
		    border: 1px solid #1b55e3;
		    color: #1b55e3;
		    &.active,
		    &:hover {
		        background-color: #1b55e3;
		        border: 1px solid #1b55e3;
		        color: #ffffff;
		        -webkit-box-shadow: 0px 0px 3px 0px rgba(27,85,227,1);
		        -moz-box-shadow: 0px 0px 3px 0px rgba(27,85,227,1);
		        box-shadow: 0px 0px 3px 0px rgba(27,85,227,1);
		    }
		 }*/
	}
}
.AreaProduct {
	& > div:nth-child(1) {
		overflow-y: hidden !important;
		overflow-x: hidden !important;
	}
	& > div > div > div:nth-child(2) {
		display: none;
	}
}
.ProductGrid.row {
	// max-height: 450px;
	margin-bottom: 0;
}
.ProductItem {
	margin-bottom: 20px;
	&:nth-last-child(-n+3) {
		margin-bottom: 0;
	}
	&.col-md-4 {
		.Product-Item {
			&-Content {
				h4 {
					font-size: 14px;
				}
				span {
					font-size: 16px;
				}
			}
		}
	}
	&.col-md-3 {
		.Product-Item {
			&-Content {
				h4 {
					font-size: 16px;
				}
				span {
					font-size: 14px;
				}
			}
		}
	}
	.Product-Item {
		// border: 2px solid transparent;
		cursor: pointer;
		border-radius: 7px;
		height: 215px;
		overflow: hidden;
		position: relative;
		&-Content {
			padding: 0;
			position: absolute;
			bottom: 0;
			width: 100%;
			h4 {
				background-color: #ffffff;
				font-weight: 600;
				padding: 5px 10px;
				margin: 0;
				border-radius: 0 7px 0 0px;
				position: relative;
			}
			span {
				background-color: #1b55e3;
				color: #ffffff;
				padding: 5px 10px;
				font-weight: 600;
				display: flex;
			}
		}
		&:hover {
			// border: 2px solid #1b55e3;
			-webkit-box-shadow: 0px 0px 10px 0px #c8c9cc;
			-moz-box-shadow: 0px 0px 10px 0px #c8c9cc;
			box-shadow: 0px 0px 10px 0px #c8c9cc;
		}
		i {
			display: none;
			position: absolute;
			font-size: 22px;
			top: 0;
			right: 5px;
			color: #1b55e3;
		}
		&:hover i {
			display: block;
		}
		.img-fluid {
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}
}
.modal-header {
	background-color: #1b55e3;
	color: #ffffff;
	.close,
	.close:not(:disabled):not(.disabled):hover {
		color: #ffffff;
		opacity: 1;
	}
}
.modal-body .ProductItem {
	&:nth-child(3n) {
		padding-right: 15px;
	}
	&:first-child,
	&:nth-child(3n + 4) {
		padding-left: 15px;
	}
	&:nth-child(3n + 2) {
		padding: 0 15px;
	}
}
