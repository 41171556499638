.strukContainer {
    display: none;
}
@media print {
    html body {
        background-color: #ffffff;
    }
    .Transaction,
    .modal {
        display: none !important;
    }
    .strukContainer {
        display: block;
        width: 300px;
        .strukHeader {
            text-align: center;
        }
        .strukHeadDesc {
            font-size: 16px;
        }
        .strukLogo {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                height: 70px;
                margin-bottom: 10px;
            }
        }
        .strukLogoTitle {
            font-size: 16px;
        }
        .strukBody {
            border-bottom: 2px dashed #000000;
            .cashier {
                font-size: 16px;
            }
            .cashier span {
                float: right;
            }
            .BodyTop {
                border-top: 2px dashed #000000;
                border-bottom: 2px dashed #000000;
                display: flex;
                justify-content: space-between;
                padding: 5px 0;
            }
            .date {
                text-align: center;
            }
            .Items-top,
            .ItemsBottom {
                display: flex;
                justify-content: space-between;
            }
            .ItemsBottom .qty {
                text-align: center;
                width: 30px;
            }
            .Items-discount {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                span {
                    margin-left: 10px;
                }
            }
            .BodyItems {
                padding: 5px 0;
            }
            .bodyBottom {
                float: right;
                padding: 10px 0;
                width: 250px;
                div {
                    display: flex;
                    justify-content: space-between;
                }
            }
            .grandtotal {
                border-top: 1px dashed #000000;
                padding: 10px 0px 0px 0px;
            }
        }
        .strukFooter {
            border-top: 2px dashed #000000;
            display: inline-block;
            padding-top: 10px;
            text-align: center;
        }
        .copyright {
            font-size: 12px;
            margin-top: 10px;
            text-align: center;
        }
    }
}
