.HistoryModal {
	.modal-body {
		ul {
			list-style: none;
			padding: 0;
			margin-bottom: 0;
		}
		.listHistory {
			border-bottom: 1px solid #1b55e3;
			padding: 10px 12px 10px 10px;
			display: flex;
			flex-wrap: wrap;
			cursor: pointer;
			&:last-child {
				margin-bottom: 0;
				border-bottom: none;
			}
			&.active {
				background-color: #1b55e3;
				color: #ffffff;
			}
		}
		.headHistoryList,
		.bodyHistory {
			display: flex;
			flex-wrap: wrap;
			// justify-content: space-between;
		}
		.headHistoryList {
			flex: 0 0 50%;
		}
		.bodyHistory {
			flex: 0 0 50%;
		}
		.orderList,
		.orderDate {
			font-weight: 700;
			display: flex;
			align-items: center;
			font-size: 12px;
			svg {
				padding-right: 10px;
				font-size: 25px;
			}
		}
		.productDetail {
			list-style: initial;
			padding: 0 15px 0 20px;
			.ProductNameSku {
				display: flex;
			}
			.Qty {
				display: flex;
				span {
					margin-left: auto;
					font-weight: 700;
				}
			}
			.Disc {
				color: #c82333;
				font-weight: 700;
			}
		}
		.priceHistory {
			margin-left: auto;
			span {
				display: block;
				font-size: 18px;
				font-weight: 700;
				text-align: right;
			}
		}
		.TableProduct {
			font-size: 14px;
		}
		.DetailTransaction {
			h5 {
				font-weight: 700;
			}
			.CalcTotal {
				.Tax,
				.Subtotal,
				.Discount,
				.Grandtotal,
				.Cash,
				.MoneyChanges {
					display: flex;
					flex-wrap: wrap;
				}
				.Discount span {
					color: #c82333;
				}
				.Grandtotal,
				.Cash,
				.MoneyChanges {
					span {
						font-size: 20px;
					}
				}
				span {
					font-weight: 700;
					margin-left: auto;
				}
				.Cash {
					margin-top: 15px;
				}
			}
		}
	}
}
