.Login {
    background-color: #fff;
    width: 350px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #c9c9c9;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 10px 0px #c8c9cc;
    -moz-box-shadow: 0px 0px 10px 0px #c8c9cc;
    box-shadow: 0px 0px 10px 0px #c8c9cc;
    
    .Loader {
        text-align: center;
        padding-top: 20px;
        &-Spinner {

        }
        &-Info {
            margin-top: 15px;
            margin-bottom: 20px;
        }
    }

    &-Header {
        text-align: center;
        padding-top: 50px;
        padding-bottom: 0px;
        color: #007bff;
        a {
            color: #007bff;
            text-decoration: none;
            background-color: transparent;
            background: white;
            padding: 2px 5px;
            border: 1px solid #d7d7d7;
            border-radius: 5px;
            font-weight: bold;
        }
    }

    &-Title {
        margin-bottom: 0;
    }

    .btn {
        width: 100%;
    }
}