body {
	margin: 0;
	font-family: "Montserrat";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
		url(./assets/fonts/Montserrat/Montserrat-Regular.ttf);
}

@font-face {
	font-family: "Montserrat";
	font-weight: 900;
	src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Bold.ttf);
}
